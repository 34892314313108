import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModalPageModule } from './modal/modal.module';
import { DecimalPipe } from '@angular/common';
import { File } from '@ionic-native/file/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/langs/', '.json');
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({hardwareBackButton: false, animated: false}),
    AppRoutingModule, HttpClientModule,  
    ModalPageModule,
    TranslateModule.forRoot({ // <--- add this
    loader: { // <--- add this
      provide: TranslateLoader, // <--- add this
      useFactory: (createTranslateLoader),  // <--- add this
      deps: [HttpClient] // <--- add this
    } // <--- add this
  }),
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ], providers: [
    StatusBar,
    SplashScreen,
    DecimalPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    PhotoViewer
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
